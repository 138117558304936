import request from "@/api/request";


// 获取示范文本 文章列表
export function getPoliciesRegulations(query) {
  return request({
    url: "/api/thinkTank/policiesRegulations",
    method: "get",
    params: query,

  });
}

export function getPoliciesRegulationsById(id) {
  return request({
    url: `/api/thinkTank/policiesRegulations/${id}`,
    method: "get",

  });
}

export function getPoliciesRegulationsHotList(query) {
  return request({
    url: "/api/thinkTank/policiesRegulations/hot",
    method: "get",
    params: query,

  });
}


export function updatePoliciesRegulationsDownloads(id) {
  return request({
    url: `/api/thinkTank/policiesRegulations/downloads/${id}`,
    method: "put",
  });
}


